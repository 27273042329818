import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const IndexContainer = styled.div`
  padding: 0 15px;

  h1 {
    max-width: 650px;
  }

  h1 span {
    display: block;
  }

  h2 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    margin-bottom: calc(1.5 * var(--lh-base));
    line-height: calc(1.5 * var(--lh-base));
  }

  p {
    max-width: 690px;
  }

  @media only screen and (min-width: 1000px) {
    padding: 0;

    h2 {
      margin-bottom: var(--lh-base);
    }
  }
`

const IndexPage = () => (
  <Layout isIndex={true}>
    <SEO title="Inicio" />
    <IndexContainer>
      <h1>
        <span>Turbaux,</span> Fuchs &amp; Zorraquín
      </h1>
      <h2>Abogados</h2>
      <p>
        Estudio jurídico conformado por abogados especializados en distintas
        áreas del derecho, quienes en forma personalizada brindan soluciones
        jurídicas integrales. La experiencia acumulada en décadas de trabajo,
        sumado al perfil docente de la mayoría de sus miembros, los ponen a la
        vanguardia del derecho. Los compromisos son asumidos con
        responsabilidad, seriedad y lealtad, viéndose esto plasmado en la
        confianza de los clientes.
      </p>
    </IndexContainer>
  </Layout>
)

export default IndexPage
